export enum ETypeForm {
  PASSWORD = 'password',
  TEXT = 'text',
  DATE = 'date',
  DATEHOUR = 'datehour',
  NUMBER = 'number',
  MULTI = 'multiSelect',
  UNIQUE = 'uniqueSelect',
  NOADD = 'noAdd',
  CLIENT = 'searchClient',
  CLIENTPUBLIC = 'clientPublic',
  PRODUCT = 'searchProduct',
  CONDVTA = 'condVta',
  DINAMIC = 'dinamic',
  CLIENTPRIVATE = 'clientPrivate',
}

//mobile-desktop
export enum EFormClass {
  FULL_FOUR = 'field col-12 md:col-4',
  FULL_THREE = 'field col-12 md:col-3',
  FULL = 'field cold-12 md:col-12',
}
