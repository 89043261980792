export enum EListPrice {
  final = 'final',
  neto = 'neto',
}

export enum ETenderStatus {
  PA = 'PendienteAprobacion',
  PC = 'PendiendeCarga',
  CCC = 'CerradoConCargado',
  CSC = 'CerradoSinCarga',
}
