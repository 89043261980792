import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  saveLocal(name: string, data: any) {
    localStorage.setItem(name, CryptoJS.AES.encrypt(JSON.stringify(data), environment.crypto).toString());
  }

  getLocal(name: string) {
    const local = localStorage.getItem(name);
    if (local) {
      return JSON.parse(CryptoJS.AES.decrypt(local, environment.crypto).toString(CryptoJS.enc.Utf8));
    }
  }

  deleteLocal(name: string) {
    localStorage.removeItem(name);
  }
}
