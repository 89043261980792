export enum ETypeTable {
  ACTION = 'action',
  STRING = 'string',
  OBJECT = 'object',
  DATE = 'date',
  IMAGE = 'image',
  ENUM = 'enum',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  FLOAT = 'float',
  TEXTAREA = 'textarea',
}
