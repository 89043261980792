export enum ESincronize {
  USER = '/sbdpigal/sincronize-user',
  CLIENT = '/sbdpigal/sincronize-clients',
  PRODUCT = '/sbdpigal/sincronize-products',
  CONDVNTA = '/sbdpigal/sincronize-condvta',
  PROJECT = '/sbdpigal/sincronize-projects',
  BRANDS = '/igalbid/sincronize-brands',
  SITIVA = '/manager/sincronize-sitiva',
  PVR = '/manager/sincronize-pvr',
  IB = '/manager/sincronize-IB',
  SUPPLIER = '/igalbid/sincronize-suppliers'
}
