import { Injectable, inject } from '@angular/core';
import { ELocalStorage, ERoles } from '@shared/enum';
import { IUser } from '@shared/interface';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from '@core/services/logic/local.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private user = new BehaviorSubject<any | null>(null);
  private token = new BehaviorSubject<string | null>(null);

  router = inject(Router);
  serviceLocal = inject(LocalStorageService);

  token$ = this.token.asObservable();
  user$ = this.user.asObservable();

  constructor() {
    this.loadUserFromLocalStorage();
  }

  public get getUser(): any {
    return this.user.value;
  }

  getLocalUser() {
    return this.serviceLocal.getLocal(ELocalStorage.USER_DATA);
  }

  isAdmin() {
    if (this.user.value.roles.includes(ERoles.ADMIN)) {
      return true;
    } else {
      return false;
    }
  }

  login(user: IUser): void {
    this.user.next(user);
    this.token.next(user.jwt);
    this.serviceLocal.saveLocal(ELocalStorage.USER_DATA, JSON.stringify(user));
    this.router.navigateByUrl('/admin');
  }

  private loadUserFromLocalStorage(): void {
    const userFromLocal = this.serviceLocal.getLocal(ELocalStorage.USER_DATA);
    if (!userFromLocal) {
      return;
    }
    const user = JSON.parse(userFromLocal);
    userFromLocal && this.user.next(user);
  }

  signOf() {
    this.user.next(null);
    this.serviceLocal.deleteLocal(ELocalStorage.USER_DATA);
    this.router.navigateByUrl('/auth/login');
  }
}
